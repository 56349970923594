// @ts-check
const { gql } = require("@apollo/client")

/**
 * All these queries are made to the GraphQL API of Contentful. We can't use endpoint used during the develop process
 * because it won't be available when site is built and deployed.
 */

const GET_PRODUCTS_FOR_WIDGET_BY_QUERY = gql`
  query GetProductsForWidgetByQuery($query: String) {
    productCollection(
      where: { OR: [{ title_contains: $query }, { sku_contains: $query }] }
    ) {
      items {
        thumbnail {
          url
        }
        title
        objectId
      }
    }
  }
`

const GET_PRODUCT_FOR_WIDGET_BY_ID = gql`
  query GetProductForWidgetById($id: String) {
    productCollection(where: { objectId: $id }, limit: 1) {
      items {
        thumbnail {
          url
        }
        title
        objectId
      }
    }
  }
`

const GET_PRODUCTS_BY_IDS_QUERY = gql`
  fragment ProductCardFields on Product {
    sys {
      id
    }
    handle
    imagePacksCollection(limit: 2) {
      items {
        isFemale
        featuredImage {
          title
          width
          height
          url
          __typename
        }
        alternateImage {
          title
          url
          width
          height
          __typename
        }
        __typename
      }
      __typename
    }
    sku
    title
    objectId
    __typename
  }

  query GetProductsByIds($ids: [String], $limit: Int) {
    productCollection(where: { objectId_in: $ids }, limit: $limit) {
      items {
        ...ProductCardFields
      }
      __typename
    }
  }
`

const GET_COLLECTIONS_BY_ID = gql`
  query GetCollectionsByIds($ids: [String], $limit: Int) {
    collectionCollection(where: { sys: { id_in: $ids } }, limit: $limit) {
      items {
        name
        slug
        sys {
          id
        }
      }
    }
  }
`

const GET_COLLECTIONS_FOR_WIDGET_BY_QUERY = gql`
  query GetCollectionsByIds($query: String) {
    collectionCollection(where: { name_contains: $query }) {
      items {
        name
        sys {
          id
        }
        metaImage {
          url
        }
      }
    }
  }
`

const GET_COLLECTION_FOR_WIDGET_BY_ID = gql`
  query GetCollectionById($id: String) {
    collectionCollection(where: { sys: { id: $id } }) {
      items {
        name
        sys {
          id
        }
        metaImage {
          url
        }
      }
    }
  }
`

module.exports = {
  GET_PRODUCTS_FOR_WIDGET_BY_QUERY,
  GET_PRODUCTS_BY_IDS_QUERY,
  GET_PRODUCT_FOR_WIDGET_BY_ID,
  GET_COLLECTIONS_BY_ID,
  GET_COLLECTIONS_FOR_WIDGET_BY_QUERY,
  GET_COLLECTION_FOR_WIDGET_BY_ID,
}
